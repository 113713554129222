const operator = '4de2a7cb-bd28-4111-aa28-4bbcdf60f533'

const stagingConfig = {
  operatorUUID: operator,
  theme: 'evo-hp',
  logo: 'logo.png',
  currency: 'CHF',
  showRegions: false,
  paymentMethods: ['credit_card', 'sepa'],
  mobilePrefix: '+41',
  homeURL: 'https://evofitness.ch',
  termsURL: 'https://evofitness.ch',
  friendlyCaptchaSitekey: null,
  locales: {
    all: ['de-CH', 'en-CH'],
    defaultLocale: 'de-CH'
  },
  tracking: {
    gtm: {
      id: undefined
    },
    adtraction: {
      c: undefined,
      tp: undefined,
      ap: undefined
    }
  },
  dibs: {
    callbackURL: `https://dibs-api.staging.credlock.net/${operator}/signup`,
    test: 'yes'
  },
  mypage: {
    url: 'https://mypage-ui-ch.staging.credlock.net'
  }
}

export default stagingConfig
