import React from 'react'

import { withTranslation as translate } from 'react-i18next'
import API from '../../services/api'
import withConfig from '../../services/withConfig'
import { withQuote } from '../../components/withQuote'
import { withRouter } from 'react-router-dom'
import FormGroup from '../../components/FormGroup'
import TextButton from './components/TextButton'
import ButtonPanel from '../../components/ButtonPanel'
import CheckoutButton from '../SignupForm/components/CheckoutButton'
import Error from '../SignupForm/components/Error'
import dibsURL from '../../services/dibsURL'

class VerifyCode extends React.Component {
  constructor (props) {
    super(props)

    this.code = ''
    this.otpInput = React.createRef()

    this.state = {
      error: null,
      sendText: props.t('signup.verification.resend')
    }

    this.errorMessageMappings = {
      missing: 'signup.verification.error.blank',
      invalid: 'signup.verification.error.invalid',
      incorrect: 'signup.verification.error.incorrect'
    }
  }

  // This should allow autofill of OTP from SMS on mobile devices
  requestOTP = () => {
    navigator.credentials.get({ otp: { transport: ['sms'] } })
      .then((otp) => {
        if (this.otpInput.current) {
          this.otpInput.current.value = otp.code
          this.code = otp.code
        }
      })
      .catch((err) => {
        // ignore errors for OTP not available or OTP cancelled
        if (!(
          err.toString() === 'InvalidStateError: OTP backend unavailable.' ||
          err.toString() === 'AbortError: OTP retrieval was cancelled.'
        )) {
          console.error('Error fetching OTP', err)
        }
      })
  }

  componentDidMount () {
    if ('OTPCredential' in window) {
      this.requestOTP()
    }
  }

  errorMessage = () => {
    if (this.state.error) {
      const messageKey = this.errorMessageMappings[this.state.error]
      return <Error messageKey={messageKey} />
    }
    return null
  }

  handleChange = (event) => {
    this.code = event.target.value
  }

  handleBack = (event) => {
    event.preventDefault()
    this.props.history.goBack()
  }

  handleInvalid = (event) => {
    event.preventDefault()
    if (this.code === '' || this.code === undefined) {
      this.setState({ error: 'missing' })
    } else {
      this.setState({ error: 'invalid' })
    }
  }

  // copied from SignupForm
  gotoPayment = () => {
    const { config, i18n, quote, history } = this.props

    if (quote.paymentMethod.provider === 'dibs') {
      window.location.href = dibsURL(quote, i18n.language, config)
    } else if (quote.paymentMethod.provider === 'slimpay') {
      window.location = quote.paymentMethod.details.url
    } else if (quote.paymentMethod.provider === 'verifone') {
      // Will forward props so we can access paymentMethod.details.url in credit-card view
      history.replace({ pathname: '/credit-card' })
    } else if (quote.paymentMethod.provider === 'invoice') {
      history.replace('/invoice-payment')
    } else {
      throw new Error(`Payment provider ${quote.paymentMethod.provider} not supported`)
    }
  }

  handleConfirm = (event) => {
    if (event.target.form && !event.target.form.checkValidity()) {
      event.target.form.reportValidity()
      return
    }
    event.preventDefault()
    const params = {
      operator_id: this.props.operatorUUID,
      quote_id: this.props.quoteUUID,
      code: this.code
    }
    API.confirmVerificationCode(params, (resp) => {
      this.gotoPayment()
    },
    (resp) => {
      this.setState({ error: 'incorrect' })
    })
  }

  handleNewCode = (event) => {
    API.requestVerificationCode({
      operator_id: this.props.operatorUUID,
      quote_id: this.props.quoteUUID,
      domain: window.location.hostname,
      nonce: sessionStorage.getItem('nonce')
    }, (resp) => {
      this.setState({ nonce: resp.nonce })
      sessionStorage.setItem('nonce', resp.nonce)
    })
    event.target.disabled = true
    event.target.classList.add('disabled')
    event.target.text = this.props.t('signup.verification.sending')
    this.setState({ sendText: this.props.t('signup.verification.sending') })
    setTimeout(() => {
      this.setState({ sendText: this.props.t('signup.verification.resend') })
      event.target.disabled = false
      event.target.classList.remove('disabled')
    }, 2000)
    this.requestOTP()
  }

  render () {
    const { t, quote } = this.props
    return (
      <>
        <h1>{t('signup.profile.title')}</h1>
        <div className='signup-form-container'>
          <form className='signup-form' id='signup-verify' onSubmit={this.handleConfirm}>
            <main>
              <FormGroup title={t('signup.verification.title')} id='verify-group'>
                <div className='comment'>
                  <p>{t('signup.verification.verify', { prefix: quote.mobilePrefix, number: quote.mobileNumber, interpolation: { prefix: '%{', suffix: '}' } })}</p>
                  <TextButton
                    type='button'
                    onClick={this.handleBack}
                    align='subnote'
                    text={t('signup.verification.change')}
                    size='small'
                  />
                </div>

                <div className='code'>
                  <input
                    ref={this.otpInput}
                    type='text' id='otp' name='otp'
                    className={this.errorMessage() ? 'error' : ''}
                    maxLength='6' pattern='[0-9]{6}' autoFocus required
                    onInvalid={this.handleInvalid}
                    onChange={this.handleChange}
                    placeholder={t('signup.verification.input')}
                    autoComplete='one-time-code'
                  />
                  {this.errorMessage()}
                </div>
              </FormGroup>
              <FormGroup id='verify-resend'>
                <div className='text-button'>
                  <TextButton
                    onClick={this.handleNewCode}
                    text={this.state.sendText}
                  />
                </div>
              </FormGroup>
            </main>
            <footer>
              <ButtonPanel>
                <CheckoutButton onSubmit={this.handleConfirm} />
              </ButtonPanel>
            </footer>
          </form>
        </div>
      </>
    )
  }
}

export default translate()(withRouter(withConfig(withQuote(VerifyCode))))
