import { toCamelCase } from '../toCamelCase'

const get = (path, params, success, error = () => {}) => {
  let responseCode

  fetch(path)
    .then(response => {
      responseCode = response.status
      if (!response.ok) {
        console.error('got error from GET', responseCode, response.statusText)
        throw Error(response.statusText)
      }
      return response
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      success(toCamelCase(json))
    })
    .catch(err => {
      console.error('error from GET', err)
      throw err
    })
}

const post = (path, params, success, error = () => {}) => {
  let responseCode

  fetch(path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params)
  })
    .then(response => {
      responseCode = response.status

      return response.json()
    })
    .then(json => {
      if (responseCode === 200 || responseCode === 202) {
        success(toCamelCase(json))
      } else {
        error(responseCode, toCamelCase(json))
      }
    })
    .catch(err => {
      console.log('error from post to', path, err)
      error(responseCode, err.message)
    })
}

export { get, post }
