import { v4 as uuidV4 } from 'uuid'
import { toCamelCase } from '../toCamelCase'

const MockAPI = {

  getTestOperatorUUID: function () {
    const request = new XMLHttpRequest()
    request.open('GET', 'https://api-gateway-signup-credlock.apps.redpill-linpro.com/v1/operators/test', false)
    request.send(null)

    if (request.status === 200) {
      return request.responseText
    }
  },

  getQuote: function (params, success) {
    success({
      status: 'payment_accepted',
      mypageAccessKey: 'secret123'
    })
  },

  makeSignupPayment: function (params) {
    console.log('Mock API.makeSignupPayment(params) invoked')
  },

  updateQuote: function (params, success) {
    success(toCamelCase({
      uuid: 'fe21f2a1-c438-4d71-82f0-17e556562fe6',
      begin_date: '2018-07-16',
      presentation: 'Ved bruk av denne kampanjekoden vil du motta tre måneders gratis medlemskap hos EVO. For å aktivere ditt medlemskap må du fullføre og fylle ut alle delene i bli-medlems prosessen.Ved bruk av denne kampanjekoden vil du også motta 5 klipp á 50 minutter med personlig trener. En av våre personlig trenere vil kontakte deg for å avtale tid.',
      current_payment: {
        total: 43429,
        date: '2018-07-16',
        lines: [
          {
            amount: 24900,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'signup_fee',
            human_concept: null,
            period: null
          },
          {
            amount: 18529,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'base_service_rest_of_month_fee',
            human_concept: null,
            period:
            {
              begin_date: '2018-07-16',
              end_date: '2018-07-31'
            }
          }
        ]
      },
      next_payment: {
        total: 35900,
        date: '2018-08-01',
        lines:
        [
          {
            amount: 35900,
            product_type: 'membership',
            product_id: 1,
            system_concept: 'base_service_rest_of_month_fee',
            human_concept: null,
            period: {
              begin_date: '2018-08-01',
              end_date: '2018-08-31'
            }
          }
        ]
      },
      validation_errors: {},
      require_verification: true,
      nonce: '934e1853-430b-4161-a752-fbe88c9840b2'
    }))
  },

  completeQuote: function (params, success, failure) {
    // mock errors based on what is actually filled in in the form
    const errs = {}
    if (!params.uuid) { errs.location_id = ['missing'] }
    if (!params.operator_id) { errs.operator_id = ['missing'] }
    if (!params.location_id || params.location_id === 'undefined') { errs.location_id = ['missing'] }
    if (params.code && !params.code.match(/^[A-Z0-9]{3}/)) { errs.code = ['invalid_promotion_or_referral_code'] }
    const user = {}
    if (!params.user.first_name) { user.first_name = ['missing'] }
    if (!params.user.last_name) { user.last_name = ['missing'] }
    if (!params.user.gender) { user.gender = ['missing'] }
    if (!params.user.birthdate) {
      user.birthdate = ['missing']
    } else {
      const bdate = new Date(Date.parse(params.user.birthdate))
      const now = new Date()
      if (bdate.getFullYear() + 18 > now.getFullYear()) {
        user.birthdate = ['must_be_over_minimum_age']
      }
    }
    if (!params.user.email) { user.email = ['missing'] }
    if (!params.user.email.match(/.+@.+/)) { user.email = ['invalid_format'] }
    if (!params.user.email_confirmation) { user.email_confirmation = ['missing'] }
    if (!params.user.email.match(/.+@.+/)) { user.email = ['invalid_format'] }
    if ((params.user.email || params.user.email_confirmation) && params.user.email !== params.user.email_confirmation) {
      if (user.email) {
        user.email.push('emails_must_match')
      } else {
        user.email = ['emails_must_match']
      }
      if (user.emailConfirmation) {
        user.emailConfirmation.push('emails_must_match')
      } else {
        user.emailConfirmation = ['emails_must_match']
      }
    }
    if (!params.user.password) { user.password = ['missing'] }
    if (!params.user.password_confirmation) { user.password_confirmation = ['missing'] }
    if (params.user.password !== params.user.password_confirmation) {
      user.password = ['passwords_must_match']
      user.passwordConfirmation = ['passwords_must_match']
    }
    if (!params.user.payment_method) { user.payment_method = ['missing'] }
    const address = {}
    if (!params.user.address.street) { address.street = ['missing'] }
    if (!params.user.address.postal_code) { address.postal_code = ['missing'] }
    const mobile = {}
    if (!params.user.mobile.number.match(/^\d{7,}$/)) { mobile.number = ['invalid_format'] }
    if (!params.user.gdpr_opt_in) { user.gdpr_opt_in = ['missing'] }
    if (!params.user.accept_terms || (params.user.accept_terms !== 'true' && params.user.accept_terms !== true)) { user.accept_terms = ['missing'] }

    if (Object.keys(address).length > 0) { user.address = address }
    if (Object.keys(mobile).length > 0) { user.mobile = mobile }
    if (Object.keys(user).length > 0) { errs.user = user }

    if (Object.keys(errs).length > 0) {
      failure(422, toCamelCase(errs))
      return
    }

    this.updateQuote(params, (quote) => {
      quote.nonce = '48c33722-8e5d-4717-b00e-362da860e3dd'
      console.log(quote)

      if (params.user.payment_method === 'credit_card') {
        // NOTE: Verifone Checkout ID most like expired
        const checkout = '4a88b47c-be2a-49cc-8d6c-c825d9f594bd'

        quote.payment_method = {
          type: 'credit_card',
          provider: 'verifone',
          details: {
            url: `https://cst.checkout.vficloud.net/v2/loader.js?checkoutId=${checkout}`,
            id: checkout
          }
        }
      } else if (params.user.payment_method === 'sepa') {
        quote.payment_method = {
          type: 'sepa',
          provider: 'slimpay',
          iframe: 'xyz'
        }
      } else {
        quote.payment_method = {
          type: 'invoice',
          provider: 'invoice'
        }
      }

      success(toCamelCase(quote))
    })
  },

  fetchLocations: function (params, success) {
    success([
      {
        name: 'Oslo og Akershus',
        id: '6dd67124-ffa9-472c-84d9-4dbccdb3c4bb',
        locations: [
          {
            id: '11cdcf9a-b620-4076-bb51-68b7d29d5aad',
            name: 'EVO Oscarsgate',
            isOpen: true
          },
          {
            id: '7c3a3b38-d027-49a1-928a-57da02908063',
            name: 'EVO Majorstua',
            isOpen: true
          },
          {
            id: '8315f283-2508-492d-940b-5a03ffdd1f9d',
            name: 'EVO Nydalen',
            isOpen: true
          }
        ]
      },
      {
        name: 'Vestfold',
        id: '2c6a9081-eb3e-4806-8336-2d55ecff3715',
        locations: [
          {
            id: '100b8c7a-850e-4ca9-a777-f317a4edfd41',
            name: 'EVO Larvik',
            isOpen: true
          },
          {
            id: 'a1a308fa-50c0-4f14-a775-d7d144850adb',
            name: 'EVO Sandefjord',
            isOpen: false
          }
        ]
      },
      {
        name: 'Rogaland',
        id: '0919d68c-594b-4c1e-a044-b7791c2e884b',
        locations: [
          {
            id: 'c0336ec5-37c8-47eb-9198-9f5dda99eb2e',
            name: 'EVO Forus',
            isOpen: true
          },
          {
            id: '8457b09e-4989-4a2a-b1db-0e90589f164d',
            name: 'EVO Bryne',
            isOpen: true
          },
          {
            id: 'fa0b63d4-5bc6-483b-bb08-187d1114bd87',
            name: 'EVO Bjergsted',
            isOpen: true
          }
        ]
      }
    ])
  },

  // API only returns promotions with location restrictions
  fetchPromotions: function (params, success) {
    success([
      {
        name: 'Club promotion',
        code: 'MAJ',
        locations: [
          {
            id: '7c3a3b38-d027-49a1-928a-57da02908063',
            name: 'EVO Majorstua',
            open_date: '2022-01-01'
          }
        ]
      },
      {
        name: 'Sandefjord Presale',
        code: 'PRE',
        locations: [
          {
            id: 'a1a308fa-50c0-4f14-a775-d7d144850adb',
            name: 'EVO Sandefjord',
            open_date: '2028-01-01'
          }
        ]
      }
    ])
  },

  requestVerificationCode: function (params, success) {
    success({
      status: 202,
      message: 'Verification code sent',
      nonce: uuidV4()
    })
  },

  confirmVerificationCode: function (params, success, failure) {
    if (params.code === '999999') {
      failure({
        status: 400,
        message: 'Invalid verification code'
      })
    } else {
      success({
        status: 200,
        message: 'Verified'
      })
    }
  },

  fetchPrefixList: function (params, success) {
    success([
      { prefix: '+34', whitelisted: true, validator: '^[67][0-9]{8}$', length: '9' },
      { prefix: '+40', whitelisted: true, validator: '^[7][0-9]{7}$', length: '8' },
      { prefix: '+41', whitelisted: true, validator: '^[7][0-9]{7}$', length: '8' },
      { prefix: '+43', whitelisted: true, validator: '^[6][0-9]{6,12}$', length: '7-13' },
      { prefix: '+44', whitelisted: true, validator: '^[7][0-9]{9}$', length: '10' },
      { prefix: '+45', whitelisted: true, validator: '^[2-9][0-9]{7}$', length: '8' },
      { prefix: '+46', whitelisted: true, validator: '^[7][0-9]{7}$', length: '8' },
      { prefix: '+47', whitelisted: true, validator: '^[459][0-9]{7}$', length: '8' },
      { prefix: '+48', whitelisted: true, validator: '^[4-8][0-9]{8}$', length: '9' },
      { prefix: '+49', whitelisted: true, validator: '^1[5-7][0-9]{8,9}$', length: '10-11' }
    ])
  }
}

export default MockAPI
